<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-permission="['wordadd']">
					<el-button @click="add" color="#488bff" class="el-white" style="color: white" size="mini">创建工会
					</el-button>
				</li>
				<li v-permission="['worddel']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入公司名称">
					</el-input>
				</li>
				<li>
					<el-select clearable v-model="form.establish_status" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="未成立" :value="1"></el-option>
						<el-option label="成立中" value="2"></el-option>
						<el-option label="已成立" :value="3"></el-option>
					</el-select>
				</li>
				<li>
					<el-button @click="search" color="#488bff" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
				<li>
					<el-button @click="ghexport" color="#488bff" class="el-white" style="color: white" size="mini">导出Exl
					</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" />
				<el-table-column property="status" label="操作员">
					<template #default="scope">
						{{scope.row.opuser?scope.row.opuser.name||scope.row.name:''}}
					</template>
				</el-table-column>
				<el-table-column property="company_name" label="公司名称" />
				<el-table-column property="name" label="公司性质">
					<template #default="scope">
						<span v-if="scope.row.nature_type==1">私营企业</span>
						<span v-if="scope.row.nature_type==2">国有企业</span>
						<span v-if="scope.row.nature_type==3">公司性质</span>
						<span v-if="scope.row.nature_type==4">机关事业单位</span>
						<span v-if="scope.row.nature_type==5">社会团体</span>
						<span v-if="scope.row.nature_type==6">其他</span>
					</template>
				</el-table-column>
				<el-table-column property="number" label="职工人数">
				</el-table-column>
				<el-table-column property="name" label="姓名">
				</el-table-column>
				<el-table-column property="position" label="职务">
				</el-table-column>
				<el-table-column property="meet_time" label="开会时间">
					<template #default="scope">
						{{scope.row.meet?scope.row.meet.meet_time:''}}
					</template>
				</el-table-column>
				<el-table-column property="status" label="状态">
					<template #default="scope">
						<el-tag type="warning" v-if="scope.row.establish_status==1">未成立</el-tag>
						<el-tag v-if="scope.row.establish_status==2">成立中</el-tag>
						<el-tag v-if="scope.row.establish_status==3" type="success">已成立</el-tag>
					</template>
				</el-table-column>
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column property="address" label="操作" width="300">
					<template #default="scope">
						<el-button v-permission="['wordinfo']" @click="bindread(scope.row.id)" size="mini">详细资料
						</el-button>
						<el-button v-permission="['wordtime']" @click="setTime(scope.row.id)" size="mini">编辑开会时间
						</el-button>
						<el-button v-if="scope.row.establish_status==3" v-permission="['wordmodify']"
							@click="modify(scope.row.id)" size="mini">修改操作员
						</el-button>
						<el-button v-permission="['wordresult']" size="mini" @click="binresult(scope.row.id)">选举结果报告
						</el-button>
						<el-button v-permission="['wordlist']" size="mini"
							@click="$router.push({path:'/uselist',query:{id:scope.row.id}})">会员列表
						</el-button>
						<el-button v-permission="['worddel']" size="mini" @click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 详情 -->
		<read ref="read"></read>
		<!-- 编辑开会时间 -->
		<setTime ref="setTime" @SubmitForm="getList" />
		<!-- 选举结果报告 -->
		<result ref="result" />
		<!-- 修改操作员 -->
		<modify ref="modify" @SubmitForm="getList" />
		<!-- 添加工会 -->
		<addgh ref="addgh" @SubmitForm="getList"></addgh>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/word";
	import read from '@/components/read.vue'
	import setTime from '@/components/setTime.vue'
	import result from '@/components/result.vue'
	import modify from './components/modify.vue'
	import addgh from './components/addgh.vue'
	export default {
		components: {
			read,
			setTime,
			result,
			modify,
			addgh
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					keyword: '',
					page: 1,
					limit: 15,
					establish_status: 3,
					status: 1
				},
				tableData: [],
				idlist: [],
			}
		},
		created() {
			if (this.$route.query.establish_status) {
				this.form.establish_status = this.$route.query.establish_status
			}
			this.getList()
		},
		methods: {
			// 添加工会
			add() {
				this.$refs.addgh.show()
			},
			// 修改操作员
			modify(id) {
				this.$refs.modify.show(id)
			},
			// 导出
			ghexport() {
				if (this.tableData.length == 0) {
					ElMessage({
						message: '暂无可导出的数据',
						type: 'warning',
					})
				} else {
					this.$http.post(api.ghexport, {
							status: 1
						})
						.then((res) => {
							window.location.href = res.data.url
						})
				}
			},
			// 选举结果报告
			binresult(id) {
				this.$refs.result.show(id)
			},
			// 图片预览
			srcList(url) {
				let list = []
				if (url) {
					list = url.split(",")
					return list
				} else {
					return list
				}
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.status = 1
				this.form.establish_status = 3
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 详情
			bindread(id) {
				this.$refs.read.show(id)
			},
			// 开会时间
			setTime(id) {
				this.$refs.setTime.show(id)
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
	.contents {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	video {
		width: 100%;
		height: 100px;
	}
</style>
